import { useEffect, useState } from "react";
import { logError } from "utils/error";
import { useToggleState } from "utils/hooks";
import { is_empty } from "utils/validations";
import { fetchBookedListingTypes } from "./CustomerTransactions.apiCalls";

/**
 * Custom hook to fetch booked listing types for a customer.
 *
 * @param {string} username - The username of the customer whose booked listing types are to be fetched.
 * @returns {object} - An object containing `isLoading` (boolean) and `bookedListingTypes` (array).
 */
const useCustomerBookedListingTypes = (username) => {
  const [bookedListingTypes, setBookedListingTypes] = useState([]);
  const [isLoading, startLoading, stopLoading] = useToggleState(false);

  const handleError = (error) =>
    logError({
      error,
      when: "Fetching customer booked listing types",
      occuredAt:
        "src/features/Crm/modules/CustomerTransactions/utils/useCustomerBookedListingTypes.js",
      severity: "blocker",
    });

  const handleFetchBookedListingTypes = (username) => {
    if (isLoading || is_empty(username)) return;

    startLoading();

    fetchBookedListingTypes(username)
      .then(setBookedListingTypes)
      .catch(handleError)
      .finally(stopLoading);
  };

  useEffect(() => {
    handleFetchBookedListingTypes(username);
  }, [username]);

  return { isLoading, bookedListingTypes };
};

export default useCustomerBookedListingTypes;
