// if: lines of code > 300
// then: breakdown this file and move to src/features/CrmResponses/constants folder

export const action_type_mapping = {
  PRE_BOOKING_QUESTIONS: {
    value: 1,
    label: "Pre-booking Form",
    slug: "Pre-booking",
  },
  POST_BOOKING_QUESTIONS: {
    value: 2,
    label: "Post-booking Form",
    slug: "Post-booking",
  },
  GET_IN_TOUCH: {
    value: 3,
    label: "Get In Touch Form",
    slug: "Get in touch",
  },
};

export const action_tabs = [
  {
    value: action_type_mapping.GET_IN_TOUCH.slug,
    label: action_type_mapping.GET_IN_TOUCH.slug,
  },

  {
    value: action_type_mapping.PRE_BOOKING_QUESTIONS.slug,
    label: action_type_mapping.PRE_BOOKING_QUESTIONS.slug,
  },
  {
    value: action_type_mapping.POST_BOOKING_QUESTIONS.slug,
    label: action_type_mapping.POST_BOOKING_QUESTIONS.slug,
  },
];
