import { hasSubscriptionStarted } from "../../../utils/ManageBookings.utils";
import { TELEGRAM_GROUP_STATES } from "../modules/TelegramGroupState/TelegramGroupState.constants";

export const showTelegramActions = (
  isTelegramCommunity,
  record,
  isSubscription = false
) => {
  const showActions =
    isTelegramCommunity &&
    record?.tg_link_status &&
    record.tg_link_status !== TELEGRAM_GROUP_STATES.GROUP_EXIT;
  if (!isSubscription) return showActions;
  return showActions && hasSubscriptionStarted(record.subscription_start_date);
};
