import { apiMethods } from "data/api.constants";
import { CRM_NOTES_API_END_POINTS } from "../constants/API";
import { dataProvider } from "data";
import { isAfter, parseISO } from "date-fns";

export const getNotes = async ({ email, page }) => {
  if (!email) return;

  const params = {
    email,
    page,
  };
  return await dataProvider.custom_request(
    CRM_NOTES_API_END_POINTS.GET_NOTES_LIST,
    apiMethods.GET,
    params
  );
};

export const addNotes = async ({ message, emailId }) => {
  if (!emailId || !message) return;

  return await dataProvider.custom_request(
    CRM_NOTES_API_END_POINTS.ADD_NOTE,
    apiMethods.POST,
    {
      message: message,
      email: emailId,
    }
  );
};

export const isEdited = ({ createdAt, updatedAt }) => {
  const createdDate = parseISO(createdAt);
  const updatedDate = parseISO(updatedAt);

  return isAfter(updatedDate, createdDate);
};
