import ExlyModal from "common/Components/ExlyModal";
import React from "react";
import Responses from "../Responses/Responses";
import cssStyles from "./ExlyResponsesModalStyles.module.css";

const ExlyResponsesModal = ({ showResponsesModal, setShowResponsesModal }) => {
  return (
    <ExlyModal
      title="Responses"
      header="Responses"
      open={showResponsesModal}
      onClose={() => setShowResponsesModal(false)}
      customFooter={<></>}
      modal_props={{
        modalPaperClassName: cssStyles.desktopModalPaper,
      }}
      padded={false}
    >
      <Responses />
    </ExlyModal>
  );
};

export default ExlyResponsesModal;
