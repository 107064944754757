import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";
import { setSessionStorageItem } from "features/Common/modules/Storage/modules/SessionStorage/utils/SessionStorage.utils";
import { logError } from "utils/error";
import { isRequestSuccessful } from "utils/Utils";

export const generateTagModalProps = ({
  processing,
  setProcessing,
  setShowAddTagsModal,
  uniqueRowId,
  leadEmailId,
  setLeadEmailId,
  onTagsAssignPerRecord,
  onTagsAssign,
  selectedRecords,
  entityName,
  tableName,
  showAddTagsModal,
  feature,
}) => {
  const tagsModalProps = {
    ctaText: processing ? "..." : "Save",
    key: "AddTag",
    open: showAddTagsModal,
    onClose: () => {
      setLeadEmailId(null);
      setShowAddTagsModal(false);
    },
    feature,
    onSubmit: (values, tagArraylength) =>
      handleAssignTags({
        values,
        tagArraylength,
        processing,
        setProcessing,
        setShowAddTagsModal,
        uniqueRowId,
        leadEmailId,
        onTagsAssignPerRecord,
        setLeadEmailId,
        onTagsAssign,
        selectedRecords,
        entityName,
        tableName,
      }),
  };
  return tagsModalProps;
};

export const handleAssignTags = async ({
  values,
  tagArraylength,
  processing,
  setProcessing,
  setShowAddTagsModal,
  uniqueRowId,
  leadEmailId,
  onTagsAssignPerRecord,
  onTagsAssign,
  selectedRecords,
  entityName,
  tableName,
  showFirstTagsModal,
  setLeadEmailId,
}) => {
  try {
    if (processing) return;
    setProcessing(true);
    let emails = [];
    let status;
    const hideTagsModal = () => setShowAddTagsModal(false);

    if (leadEmailId) {
      emails.push(leadEmailId);
      if (uniqueRowId) {
        // @dev - setting id of the table row to be used to scroll to that row after data refetch
        setSessionStorageItem(SESSION_STORAGE_KEYS.TABLE_ROW_ID, uniqueRowId);
      }
      const { status: onTagsAssignPerRecordStatus } =
        await onTagsAssignPerRecord(values, hideTagsModal, emails);
      setLeadEmailId(null);
      status = onTagsAssignPerRecordStatus;
    } else {
      const { status: tagsStatus } = await onTagsAssign({
        values,
        hideTagsModal,
        selectedRecords,
        tableName,
        entityName,
      });
      status = tagsStatus;
    }
    //Checking if array length is 0 and tag gets assigned then calling the first tag modal
    if (!tagArraylength && isRequestSuccessful(status)) showFirstTagsModal();
  } catch (err) {
    logError({
      error: err,
      when: "handleAssignTags",
      occuredAt: "features/Crm/modules/AssignTags/utils/AssignTags.utils.js",
    });
  } finally {
    setProcessing(false);
  }
};
