// if: lines of code > 300
// then: breakdown this file and move to src/features/crm/utils folder

import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { get_crm_responses } from "./constants/CrmResponses.apiConstants";
import { action_type_mapping } from "./constants/CrmResponses.constants";

/**
 * Fetches responses from the API based on provided parameters.
 * @param {number} pageNumber - The page number to fetch responses from.
 * @param {string} uniqueId - The unique identifier for the entity.
 * @param {string} entityType - The type of entity for which responses are fetched.
 * @param {string} actionType - The type of action associated with the responses (optional).
 * @returns {Promise<{ data: any, status: number }>} An object containing response data and status.
 * @throws {Error} Throws an error if there is a problem fetching responses.
 */
export const getResponses = async ({
  pageNumber,
  uniqueId,
  entityType,
  actionType,
}) => {
  if (!uniqueId || !entityType) return;

  let queryParams = {
    unique_id: uniqueId,
    entity_type: entityType,
    ...(actionType && { action_type: actionType }),
    ...(pageNumber && { page: pageNumber }),
  };
  const { data, status } = await dataProvider.custom_request(
    get_crm_responses,
    apiMethods.GET,
    queryParams
  );
  if (!isRequestSuccessful(status)) throw new Error(`Error fetching responses`);
  return { data, status };
};

/**
 * Parses response data based on tab value.
 * @param {string} tabValue - The value representing the current tab.
 * @param {Array} responses - Array of response objects to parse.
 * @returns {Array} An array of parsed response items based on tabValue condition.
 */
export const parsedResponseRow = (tabValue, responses) => {
  if (!tabValue || !responses) return [];
  return responses?.map((response, idx) => {
    let responseItem = {
      source: response?.source_title,
      value: response,
      idx: idx,
      responses: response?.responses,
    };

    if (tabValue !== action_type_mapping.GET_IN_TOUCH.slug) {
      responseItem.adoption = `${response?.total_responses}/${response?.total_questions} Questions answered`;
      responseItem.total_answered = response?.total_responses;
    }

    return responseItem;
  });
};
