export const TELEGRAM_SPECIFIC_COLUMNS = {
  group_state: {
    label: "Group State",
    source: "tg_link_status",
  },
  telegram_subscription_status: {
    label: "Subscription Status",
    source: "subscription_status",
  },
};
