import SessionStorageConstants from "constants/SessionStorage.constants";
import { initiateRefundRequest } from "features/Crm/modules/Transactions/modules/Refund/utils/Refund.utils";
import React from "react";
import { InitiateRefundModalContext } from "ui/pages/transactions/AllTransaction/AllTransaction";
import { logError } from "utils/error";
import { is_empty } from "utils/validations";

export const useReviewSelection = () => {
  const {
    selectedRecords,
    selectedRefundRecords,
    setSelectedRefundRecords,
    refundRequestId,
    setRefundRequestId,
    setRefundAmountData,
    setSelectedRefundType,
  } = React.useContext(InitiateRefundModalContext);
  const [processing, setProcessing] = React.useState(true);

  /**
   * to show paginated data of the selected transactions we need a refund request id of the selected transactions.
   * this refund request id is to be passed as a query param in the APIS.post_initiate_refund api
   * @returns void: sets request id in the session storage
   */
  const fetchRequestId = async () => {
    if (is_empty(selectedRecords)) return;
    try {
      if (is_empty(refundRequestId)) {
        const response = await initiateRefundRequest({
          transactionUuids: Object.keys(selectedRecords),
        });
        const requestId = response?.data?.request_id;
        setRefundRequestId(requestId);
        // need to pass REFUND_REQUEST_ID as a param in the react admin default list functionality thats why saving in sessionStorage
        // and cant be passed as a url param as we are opening exlytable in popup
        sessionStorage.setItem(
          SessionStorageConstants.REFUND_REQUEST_ID,
          requestId
        );
      }
    } catch (error) {
      logError({
        error,
        occuredAt:
          "src/features/Refund/modules/ReviewSelection/useRefundSelection.js",
        when: "fetchRequestId",
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleRowsSelected = (selectedRows) => {
    let updatedSelectedRecord = {};
    for (const selectedUuid of selectedRows) {
      updatedSelectedRecord[selectedUuid] = selectedRecords[selectedUuid];
    }
    setSelectedRefundRecords({ ...updatedSelectedRecord });
    setRefundAmountData({});
    setSelectedRefundType("");
  };

  return {
    fetchRequestId,
    handleRowsSelected,
    refundRequestId,
    processing,
    selectedIds: selectedRefundRecords
      ? Object.keys(selectedRefundRecords)
      : [],
  };
};
