import { lazy } from "react";

export const UserDetailsPopup = lazy(() =>
  import(
    "features/Crm/modules/Bookings/modules/ManageBookings/modules/TelegramBookings/modules/UserDetailsPopup/UserDetailsPopup"
  ).then((module) => ({ default: module.UserDetailsPopup }))
);
export const ShareLinkPopup = lazy(() =>
  import(
    "features/Crm/modules/Bookings/modules/ManageBookings/modules/TelegramBookings/modules/ShareLinkPopup/ShareLinkPopup"
  ).then((module) => ({ default: module.ShareLinkPopup }))
);
