export const CRM_ASSETS_RELATIVE_PATH = "assets/vectors/crm";

export const ADD_CUSTOMER_FORM_KEYS = {
  selected_price: "selected_price",
  installments: "installments",
  send_installment_comms: "send_installment_comms",
  currency: "currency",
  payment_type: "payment_type",
  customer_phone_number: "customer_phone_number",
  country_code: "country_code",
  country: "country",
  note: "note",
  payment_mode_uuid: "payment_mode_uuid",
  gateway_transaction_id: "gateway_transaction_id",
  customer_gst_details: "customer_gst_details",
  gst_number: "gst_number",
  gst_name: "gst_name",
  billing_state: "billing_state",
  tag_uuids: "tag_uuids",
  tag_name: "tag_name",
  payment_mode_name: "payment_mode_name",
  customer_timezone: "customer_timezone",
  booking_start_date: "booking_start_date",
  address_details: "address_details",
  full_name: "full_name",
  email: "email",
  phone: "phone",
};

export const TABLE_ACTIONS_IDS = {
  NOTE: "note",
  TAG: "tag",
};

export const CREATED_AT = "created_at";

export const others = "Others";
export const listing_answer_count = "Answer Count";
export const see_responses_link_text = "See Responses";
export const last_div_id = "scrollDiv";

export const INITIAL_PAGE = 1;
export const DEFAULT_SCROLL_HEIGHT = 0;
export const SCROLL_HEIGHT_OFFSET = 400;
export const SCROLL_TOP_OFFSET = 100;
export const SCROLL_TO_ROW_DELAY = 1000;
export const SCROLL_TOLERANCE = -15;
