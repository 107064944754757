import React from "react";
import styles from "./TelegramGroupState.module.css";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@my-scoot/component-library-legacy";
import {
  TELEGRAM_GROUP_STATE_LABELS,
  TELEGRAM_GROUP_STATES,
} from "./TelegramGroupState.constants";

export const TelegramGroupState = ({ group_state }) => {
  return (
    <p className={styles.groupState}>
      {TELEGRAM_GROUP_STATE_LABELS[group_state] || "-"}
      {group_state ===
        TELEGRAM_GROUP_STATES.INVITE_GENERATED_JOINING_PENDING && (
        <Tooltip
          title={
            "Joining status will be refreshed within 24 hrs of link generation."
          }
          color="primary"
          arrow
        >
          <InfoOutlinedIcon className={styles.tooltipIcon} />
        </Tooltip>
      )}
    </p>
  );
};
