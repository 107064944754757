import React, { useState } from "react";
import { useToggleState } from "utils/hooks";
import AddTagModal from "webpage-leads/components/CrmModals/AddTagsModal/AddTagModal";
import FirstTagModal from "webpage-leads/components/CrmModals/FirstTagModal/FirstTagModal";
import ShowTagsModal from "webpage-leads/components/CrmModals/ShowTagsModal/ShowTagsModal";
import { generateTagModalProps } from "./utils/AssignTags.utils";

const AssignTags = ({
  setShowAddTagsModal,
  uniqueRowId,
  leadEmailId,
  setLeadEmailId,
  onTagsAssignPerRecord,
  onTagsAssign,
  selectedRecords,
  entityName,
  tableName,
  feature,
  showAddTagsModal,
  customerName,
  customerTagsArray,
  isShowTagsModalActive,
  closeShowTagsModal,
}) => {
  const [isFirstTagsModalActive, showFirstTagsModal, hideFirstTagsModal] =
    useToggleState(false);
  const [processing, setProcessing] = useState(false);

  const tagsModalProps = generateTagModalProps({
    processing,
    setProcessing,
    setShowAddTagsModal,
    uniqueRowId,
    leadEmailId,
    onTagsAssignPerRecord,
    onTagsAssign,
    selectedRecords,
    entityName,
    tableName,
    showAddTagsModal,
    feature,
    showFirstTagsModal,
    setLeadEmailId,
  });

  return (
    <>
      {showAddTagsModal && <AddTagModal {...tagsModalProps} />}
      {isShowTagsModalActive && (
        <ShowTagsModal
          key={"ShowTags"}
          open={isShowTagsModalActive}
          onClose={closeShowTagsModal}
          customerTagsArray={customerTagsArray}
          customerName={customerName}
          leadEmailId={leadEmailId}
          feature={feature}
        />
      )}

      {isFirstTagsModalActive && (
        <FirstTagModal
          key={"FirstTagModal"}
          open={isFirstTagsModalActive}
          onClose={() => {
            hideFirstTagsModal();
            setShowAddTagsModal(false);
          }}
          onSubmit={() => {
            hideFirstTagsModal();
            setShowAddTagsModal(false);
          }}
        />
      )}
    </>
  );
};

export default AssignTags;
