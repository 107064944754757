import { debounce } from "lodash";

export const handleRefundAmountChange = debounce(
  ({
    refundAmount,
    validateRefunds,
    selectedRefundType,
    selectedRefundRecords,
  }) => {
    // collecting the transaction ids for the validate API payload
    let selectedTransactionUuids = [];

    for (const id in selectedRefundRecords) {
      selectedTransactionUuids.push(selectedRefundRecords[id].uuid);
    }

    validateRefunds({
      uuids: selectedTransactionUuids,
      val: selectedRefundType,
      refundAmount,
    });
  },
  1000
);
