import ExlyTable from "common/Components/ExlyTable";
import React from "react";
import ExlyLoader from "ui/modules/ExlyLoader";
import { is_empty } from "utils/validations";
import { getColumnConfig } from "./utils/ReviewSelection.tableConfig";
import { REVIEW_SELECTION_RA_PROPS } from "./ReviewSelection.constants";
import { useReviewSelection } from "./useReviewSelection";

export const ReviewSelection = () => {
  const {
    fetchRequestId,
    handleRowsSelected,
    refundRequestId,
    processing,
    selectedIds,
  } = useReviewSelection();

  React.useEffect(() => {
    fetchRequestId();
  }, []);

  if (processing || is_empty(refundRequestId)) return <ExlyLoader />;

  return (
    <ExlyTable
      noExportButton
      ra_props={{
        ...REVIEW_SELECTION_RA_PROPS,
      }}
      columnConfig={getColumnConfig()}
      layoutProps={{
        showFixedBars: true,
        noShadow: true,
        paddingBottom: "32px",
        paddingMobile: "0px",
        noMobileBoxShadow: true,
      }}
      fieldsLeftPadded
      borderedFields
      drawerFieldsBordered
      fieldsAlignment="space-between"
      drawerFieldsAlignment="space-between"
      recordFooterVariant="secondary"
      primaryKey="uuid"
      uniqueRowIdFieldName="uuid" // mobile primary key
      checkboxSelection
      showSelectAllcheckBox
      selected={selectedIds}
      onRowSelected={handleRowsSelected}
    />
  );
};
