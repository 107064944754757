import React from "react";
import styles from "./BulkImportInformation.module.css";

export const MAX_TAGS_PER_RECORD = 4;
export const MAX_CHARACTER_PER_TAG = 64;
export const MAX_TAGS_PER_IMPORT = 25;
export const GENERIC_STRING_LENGTH = 256;
export const EMAIL_MAX_LENGTH = 150;
export const PHONE_MAX_LENGTH = 20;
export const COUNTRY_CODE_MAX_LENGTH = 10;
export const PRICE_LENGTH = 10;
export const BOOKING_DATE_LENGTH = 10;
export const CUSTOMER_TIMEZONE_LENGTH = 32;
export const MINIMUM_RECORDS_COUNT = 2;

export const WHATS_POSSIBLE_TEXT = {
  lead: {
    heading: "What’s possible?",
    description:
      "Experience the power of Bulk import! Import leads effortlessly using email, phone number, or both. Enhance your data with UTM info and multiple Tags. Get started with the sample file for more details.",
  },
  customer: {
    heading: "What’s possible?",
    description:
      "Experience the power of Bulk import! Import your customers effortlessly. Import your data such as currency, price etc with your customer data.",
  },
};

export const api_accepted_column_keys = {
  name: "name",
  email: "email",
  country_code: "country_code",
  phone: "phone",
  utm_source: "utm_source",
  utm_medium: "utm_medium",
  utm_campaign: "utm_campaign",
  tags: "tags",
  currency: "currency",
  price: "price",
  customer_timezone: "customer_timezone",
  booking_date: "booking_date",
  subscription_start_date: "subscription_start_date",
  payment_gateway: "payment_gateway",
  gateway_transaction_id: "gateway_transaction_id",
  payment_type: "payment_type",
  payment_mode_name: "payment_mode_name",
  gst_number: "gst_number",
  billing_state: "billing_state",
  note: "note",
  gst_name: "gst_name",
};

export const ui_header_keys = {
  name: "name",
  email: "email",
  country_code: "country code",
  phone: "phone number",
  utm_source: "utm source",
  utm_medium: "utm medium",
  utm_campaign: "utm campaign",
  tags: "tags",
  currency: "currency",
  price: "price",
  customer_timezone: "timezone",
  booking_date: "booking date",
  subscription_start_date: "latest cycle start date",
  payment_gateway: "payment gateway",
  gateway_transaction_id: "gateway transaction id",
  payment_plan_type: "payment plan type",
  mode_of_payment: "mode of payment",
  transaction_id: "transaction id",
  gstin: "gstin",
  state: "state",
  notes: "notes",
  gst_name: "registered company name",
};

export const ui_header_keys_alias = {
  [ui_header_keys.transaction_id]: ui_header_keys.gateway_transaction_id,
};

export const BULK_IMPORT_PAYMENT_PLAN_TYPES = {
  ONE_TIME_PAYMENT: 1,
  PART_PAYMENT: 2,
};

export const BULK_IMPORT_PAYMENT_PLAN_TYPES_SET = new Set(
  Object.values(BULK_IMPORT_PAYMENT_PLAN_TYPES).map((type) => String(type)) // type needed as string for csv column validation
);

export const LEAD_INFORMATIONS = [
  {
    id: 1,
    heading: "You can upload leads with either Phone number or Email",
    description:
      "You can upload contacts with either a phone number or email, or both. Exly won’t restrict the upload if there is at least one contact information. Please note that if you’re opting to upload only phone number, make sure you add the country code as well.",
    image: {
      src: "/assets/images/Bulk Import/leads_no_tags.svg",
      alt: "Correct leads without tags",
      width: 657,
      height: 218,
    },
  },
  {
    id: 2,
    heading: "Column names should be unique",
    description: (
      <div>
        Columns{" "}
        <span className={styles.inter_text_style}>
          shouldn’t have same names,
        </span>{" "}
        and column names{" "}
        <span className={styles.inter_text_style}>must not be left empty.</span>{" "}
        The file will not be imported in such cases.
      </div>
    ),
    image: {
      src: "/assets/images/Bulk Import/leads_duplicate_columns.svg",
      alt: "Columns must not be duplicate",
      width: 955,
      height: 169,
    },
  },
  {
    id: 3,
    heading: "You can add multiple tags for a lead",
    description: (
      <div>
        Add just one or comma separated tags on your file. Exly will recognise
        the comma separated ones as separate tags. Please note that you can only
        add{" "}
        <span className={styles.inter_text_style}>up to 4 tags per row</span>{" "}
        and only <span className={styles.inter_text_style}>25 unique tags</span>{" "}
        per import, irrespective of whether the tag is new or already existing
        in your CRM. Also, you{" "}
        <span className={styles.inter_text_style}>
          can’t use any special characters
        </span>{" "}
        for tags except spaces. Maximum character count for a tag is{" "}
        <span className={styles.inner_numeric_style}>64.</span> You can add tags
        only for the leads that have an{" "}
        <span className={styles.inter_text_style}>email.</span>
      </div>
    ),
    image: {
      src: "/assets/images/Bulk Import/leads_add_tags.svg",
      alt: "Add one or more tags for a lead",
      width: 955,
      height: 169,
    },
  },
  {
    id: 4,
    heading: "Import your UTM info",
    description: (
      <div>
        You can import UTM info too. Just mention UTM medium, source and
        campaign separately in the CSV file. Also,{" "}
        <span className={styles.inter_text_style}>
          except space and underscore ( _ ),
        </span>{" "}
        you can’t use any other special characters.{" "}
      </div>
    ),
    image: {
      src: "/assets/images/Bulk Import/leads_utm_info.svg",
      alt: "Import UTM info",
      width: 955,
      height: 169,
    },
  },
  {
    id: 5,
    heading: "Email",
    image: {
      src: "/assets/images/Bulk Import/leads_email.svg",
      alt: "Leads email",
      width: 491,
      height: 94,
    },
  },
  {
    id: 6,
    heading: "Country Code",
    image: {
      src: "/assets/images/Bulk Import/leads_country_code.svg",
      alt: "Leads country code",
      width: 502,
      height: 77,
    },
  },
  {
    id: 7,
    heading: "Phone number",
    image: {
      src: "/assets/images/Bulk Import/leads_phone_no.svg",
      alt: "Leads phone number",
      width: 502,
      height: 77,
    },
  },
  {
    id: 8,
    heading: "Duplicate entries",
    description: (
      <div>
        Make sure there are no duplicate entries in the file. In case of
        duplicate entries, Exly will{" "}
        <span className={styles.inter_text_style}>skip</span> one of them.
      </div>
    ),
    image: {
      src: "/assets/images/Bulk Import/leads_duplicate_entries.svg",
      alt: "Leads duplicate entries",
      width: 760,
      height: 96,
    },
  },
];

export const import_page_heading = {
  lead: "Bulk Import",
  customer: "Import customers",
};

export const bulk_import_types = {
  lead: "lead_uploads",
  customer: "customer_uploads",
};

export const timezone_file_url =
  "https://docs.google.com/spreadsheets/d/1t3zKW507OlJU_EA5wfHcy2oeK-883eIWBonxMuLDM3o/edit#gid=0";

export const required_field_error = {
  lead: "Please select at least one: Email or Phone number & Country Code",
  lead_unique: "Please select both: Phone number and country code",
  customer: ({ isStateRequired }) =>
    `Name, Email, Country Code, Phone number, Currency${
      isStateRequired ? ", State" : ""
    } & Price are mandatory`,
};

export const bulk_import_api_type = {
  lead: { import_type: 1 },
  customer: { import_type: 2 },
};

export const import_v1_offerings =
  "Workshops, Regular Classes, Flexible Classes";

export const import_v2_offerings =
  ", Recorded Content, Quick Payment Pages, Whatsapp, Telegram and Branded Community";

export const download_file_max_name_length = 35;

export const BULK_IMPORT_STRIP_REFRESH_DEBOUNCE_TIME = 500;

export const NONE_OPTION_COLUMN_VALUE = "none";

export const BULK_IMPORT_AWS_CRED = {
  accessKeyId: process.env.REACT_APP_EXLY_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_EXLY_S3_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_S3_REGION,
};

export const BULK_IMPORT_BUCKET = process.env.REACT_APP_EXLY_S3_BULK_BUCKET;
