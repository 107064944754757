import { schedule_types_ids } from "constants/schedule";

export const TABS_MAP = {
  CUTOMER_ACTIVITY: 1,
  CONTENT_TRANSACTION: 2,
  COMMUNITY_ACCESS_VALIDITY: 3,
};

export const TABS_LABELS = {
  [TABS_MAP.CUTOMER_ACTIVITY]: "Activity",
  [TABS_MAP.CONTENT_TRANSACTION]: "Content Purchased Validity",
  [TABS_MAP.COMMUNITY_ACCESS_VALIDITY]: "Community access validity",
};

const CUSTOMER_ACTIVITY_TAB_OPTION = {
  label: TABS_LABELS[TABS_MAP.CUTOMER_ACTIVITY],
  value: TABS_MAP.CUTOMER_ACTIVITY,
};

const CONTENT_PURCHASE_TAB_OPTION = {
  label: TABS_LABELS[TABS_MAP.CONTENT_TRANSACTION],
  value: TABS_MAP.CONTENT_TRANSACTION,
};

const COMMUNITY_ACCESS_VALIDITY_TAB_OPTION = {
  label: TABS_LABELS[TABS_MAP.COMMUNITY_ACCESS_VALIDITY],
  value: TABS_MAP.COMMUNITY_ACCESS_VALIDITY,
};

export const LISTING_TABS_MAP = {
  [schedule_types_ids.content]: CONTENT_PURCHASE_TAB_OPTION,
  [schedule_types_ids.branded_community]: COMMUNITY_ACCESS_VALIDITY_TAB_OPTION,
};

export const TABS_LISTING_TYPES_MAP = {
  [TABS_MAP.CONTENT_TRANSACTION]: schedule_types_ids.content,
  [TABS_MAP.COMMUNITY_ACCESS_VALIDITY]: schedule_types_ids.branded_community,
};

export const CUSTOMER_ACTIVITY_TABS = [CUSTOMER_ACTIVITY_TAB_OPTION];

export const initialTabsEmptyState = {
  activity: true,
  content: true,
};
