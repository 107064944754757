import React, { useEffect } from "react";
import styles from "./CrmNotesList.module.css";

import ExlyLoader from "../../../../../../ui/modules/ExlyLoader";
import { is_empty } from "utils/validations";

import ExlyImage from "common/Components/ExlyImage";
import DateHeader from "features/Crm/modules/DateHeader/DateHeader";
import NotesCard from "../../components/NotesCard/NotesCard";
import EmptyNotesList from "../../components/EmptyNotesList/EmptyNotesList";
import { last_div_id } from "features/Crm/Crm.constants";
import { onScrollToDiv } from "features/Common/utils/common.utils";
import useInfiniteSearchToTop from "features/Crm/utils/hooks/useInfiniteSearchToTop";
import { getCrmAsset } from "features/Crm/utils/Crm.utils";

const CrmNotesList = ({ loading, notesList, getNotesList }) => {
  const { containerRef, showScrollToBottom, onScroll, page } =
    useInfiniteSearchToTop();

  useEffect(() => {
    getNotesList({ page });
  }, [page]);

  return (
    <div className={styles.listContainerWrapper}>
      {loading && <ExlyLoader className={styles.loader} showText={false} />}
      {is_empty(notesList) && !loading && <EmptyNotesList />}
      {!is_empty(notesList) && (
        <div
          ref={containerRef}
          className={styles.notesWrapper}
          onScroll={onScroll}
        >
          <div id={last_div_id}></div>
          {showScrollToBottom && (
            <div className={styles.scrollToDownWrapper}>
              <div
                className={styles.scrollToDown}
                onClick={() => onScrollToDiv({ elementId: last_div_id })}
              >
                <ExlyImage
                  src={getCrmAsset("keyboard_double_arrow_down.svg")}
                  alt="ArrowDown"
                  height={14}
                  width={14}
                  id="arrow-down"
                />
              </div>
            </div>
          )}
          {Object.keys(notesList)?.map((dateKey) => {
            return (
              <>
                {notesList[dateKey]?.map((note) => (
                  <NotesCard key={note?.uuid} data={note} />
                ))}
                <DateHeader date={dateKey} />
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CrmNotesList;
