export const leads_table_config = {
  full_name: {
    field: "full_name",
    placeholder: "Lead Name",
    filterPlaceholder: "Name",
  },
  org__uuid: {
    field: "org__uuid",
    placeholder: "Assignee",
    filterField: "org_uuid",
    filterPlaceholder: "Staff",
  },
  phone_number: { field: "phone_number", placeholder: "Phone No." },
  email: { field: "email", placeholder: "Email" },
  reason: { field: "reason", placeholder: "Last Activity" },
  status: { field: "status", placeholder: "Status" },
  lead_remarks: { field: "lead_remarks", placeholder: "Remarks" },
  answer_count: { field: "answer_count", placeholder: "Q/A responses" },
  tags: { field: "tags", placeholder: "Tags" },
  action: { field: "action", placeholder: "Actions" },
  referer_group_id: { field: "referer_group_id", placeholder: "Source" },
  source_link: { field: "source_link", placeholder: "Source Link" },
  country: { field: "country", placeholder: "Country" },
  total_spent: { field: "total_spent" },
  transactions_count: { field: "transactions_count" },
  last_transaction_date: { field: "last_transaction_date" },
  customer_name: { field: "customer_name" },
  customer_email: { field: "customer_email" },
  utm_info: { field: "utm_info", placeholder: "UTM info" },
  id: { field: "id" },
  last_occurrence_time: {
    field: "last_occurrence_time",
    placeholder: "Last Activity Date",
  },
  first_occurrence_time: {
    field: "first_occurrence_time",
    placeholder: "Added On",
  },
};
