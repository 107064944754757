import { isFuture, isValid } from "date-fns";

/**
 * Checks if a subscription has started based on the provided start date.
 *
 * @param {string|Date} subscriptionStartDate - The start date of the subscription in a valid date format.
 * @returns {boolean} - Returns `true` if the subscription date is valid and in the past or present; otherwise, returns `false`.
 *
 */
export const hasSubscriptionStarted = (subscriptionStartDate) => {
  return (
    isValid(new Date(subscriptionStartDate || "")) &&
    !isFuture(new Date(subscriptionStartDate))
  );
};
