export const RECIPIENT_SUB_SECTION_TYPES = {
  ONE_TIME_PAYMENT: 1,
  PART_PAYMENT: 2,
};

export const RECIPIENT_SUB_SECTION_LABELS = {
  [RECIPIENT_SUB_SECTION_TYPES.ONE_TIME_PAYMENT]: "One-time payment",
  [RECIPIENT_SUB_SECTION_TYPES.PART_PAYMENT]: "Part payment",
};

export const TOTAL_EMAIL_RECIPIENTS_SUBSECTIONS = Object.keys(
  RECIPIENT_SUB_SECTION_TYPES
).length;
