import React from "react";
import { getSubscriptionPermission } from "utils/AuthUtil";
import ExlyLoader from "ui/modules/ExlyLoader";
import { LOCKED_KEY } from "features/CustomCode/CustomCode.constants";
import { getIntegrationConfig } from "ui/pages/marketing/sales/integrations.helper";
import {
  integrationsCards,
  constants as integrationConstants,
} from "ui/pages/marketing/sales/integrations.constants";
import FeaturePage from "ui/pages/FeaturePage";
import Codes from "ui/pages/CustomCodes/Codes";

export const CustomCodesWrapper = (props) => {
  const [locked, setLocked] = React.useState(false);
  const [processing, setProcessing] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const planDetails = await getSubscriptionPermission();
      const config = getIntegrationConfig({
        planDetails: planDetails.creator_section_mapping,
        key: integrationsCards.custom_code.id,
      });
      setLocked(LOCKED_KEY in config ? config.locked : false);
      setProcessing(false);
    })();
  }, []);

  if (processing) return <ExlyLoader />;

  if (locked) {
    return (
      <FeaturePage
        featureName={integrationConstants.ids.custom_code}
        desktopImage={"./assets/images/custom_codes/locked_desktop.png"}
        mobileImage={"./assets/images/custom_codes/locked_mobile.png"}
        pageTitle={integrationsCards.custom_code.title}
      />
    );
  }

  return <Codes {...props} />;
};
