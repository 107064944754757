export const TELEGRAM_GROUP_STATES = {
  PENDING_LINK_GENERATION: 1,
  INVITE_GENERATED_JOINING_PENDING: 2,
  JOINED: 3,
  INVITE_EXPIRED: 4,
  GROUP_EXIT: 5,
  ERROR_GENERATING_LINK: 6,
};

export const TELEGRAM_SHARE_SCENARIOS = {
  NO_LINK_ATTEMPT: 1,
  JOINING_PENDING: 2,
  ERROR_GENERATING_LINK: 3,
  ERROR_LINK_REGENERATED: 4,
  ERROR_GENERATING_EXPIRED_LINK: 5,
  REGENERATED_EXPIRED_LINK: 6,
  FALLBACK: 7,
};

export const NO_JOINING_LINK_SCENARIOS = [
  TELEGRAM_SHARE_SCENARIOS.NO_LINK_ATTEMPT,
  TELEGRAM_SHARE_SCENARIOS.ERROR_GENERATING_LINK,
  TELEGRAM_SHARE_SCENARIOS.ERROR_GENERATING_EXPIRED_LINK,
  TELEGRAM_SHARE_SCENARIOS.FALLBACK,
];

export const TELEGRAM_GROUP_STATE_LABELS = {
  [TELEGRAM_GROUP_STATES.JOINED]: "Joined From Link",
  [TELEGRAM_GROUP_STATES.INVITE_GENERATED_JOINING_PENDING]: "Invite Pending",
  [TELEGRAM_GROUP_STATES.INVITE_EXPIRED]: "Invite Expired",
  [TELEGRAM_GROUP_STATES.GROUP_EXIT]: "No Longer in group",
  [TELEGRAM_GROUP_STATES.PENDING_LINK_GENERATION]: "Link not generated",
  [TELEGRAM_GROUP_STATES.ERROR_GENERATING_LINK]: "Link generation error",
};

export const TELEGRAM_GROUP_STATE_ACTION_LABELS = {
  [TELEGRAM_GROUP_STATES.JOINED]: "View Joining Info",
  DEFAULT: "Share Joining Link",
};
