import React from "react";
import { format } from "date-fns";
import styles from "./NotesCard.module.css";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import classnames from "classnames";
import {
  MONTH_SHORT_DAY_YEAR_TIME_FORMAT,
  DEFAULT_12_HOUR_TIME_FORMAT,
} from "features/Common/modules/DateTime/DateTime.constants";
import { Box } from "@my-scoot/component-library-legacy";
import { ReadMoreWrapper } from "@my-scoot/exly-react-component-lib";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { dataExists } from "utils/validations";
import { isEdited } from "../../utils/CrmNotes.utils";
import ExlyImage from "common/Components/ExlyImage";
import { getCrmAsset } from "features/Crm/utils/Crm.utils";

const NotesCard = ({ data }) => {
  const isNoteEdited = isEdited({
    createdAt: data?.created_at,
    updatedAt: data?.updated_at,
  });

  const isSelfNote = data?.action_by_login_user;
  const isNoteDeleted = !(data?.is_active || !dataExists(data?.is_active));

  return (
    <Box className={styles.noteCardContainer}>
      {!isNoteDeleted ? (
        <Box>
          <ReadMoreWrapper
            childDivProps={{
              id: "text",
              className: styles.messageStyle,
              dangerouslySetInnerHTML: { __html: data?.message },
            }}
            collapsedHeight={65}
            ExpandToggleButton={({ onClick, isExpanded }) => (
              <span onClick={onClick} className={styles.readMoreText}>
                {isExpanded ? "See less" : "...See more"}
              </span>
            )}
          />
        </Box>
      ) : (
        <Box className={styles.deletedWrapper}>
          <NotInterestedIcon className={styles.deletedIcon} />{" "}
          <span className={styles.deletedText}>
            Note deleted on{" "}
            {format(
              new Date(data?.updated_at),
              MONTH_SHORT_DAY_YEAR_TIME_FORMAT
            )}
          </span>
        </Box>
      )}

      <Box className={styles.noteBottomContainer}>
        <Box className={styles.noteBottomLeft}>
          <ExlyImage
            src={getCrmAsset("note_stack_solid.svg")}
            alt={"note stack"}
            className={styles.noteStackIcon}
          />
          <p className={styles.noteBottomSpanText}>Note</p>
        </Box>
        <Box className={styles.noteBottomRight}>
          <Box className={styles.noteBy}>
            <ExlyImage
              src={getCrmAsset("person_icon.svg")}
              alt={"person"}
              className={styles.personIcon}
            />
            <p className={styles.noteBottomSpanText}>
              {isSelfNote ? "You" : data?.display_name}
            </p>
          </Box>
          <FiberManualRecordIcon className={styles.dotStyle} />
          <p className={styles.noteBottomSpanText}>
            {format(new Date(data?.created_at), DEFAULT_12_HOUR_TIME_FORMAT)}
          </p>
          {!isNoteDeleted && isNoteEdited && (
            <>
              <FiberManualRecordIcon className={styles.dotStyle} />
              <p
                className={classnames(
                  styles.noteBottomSpanText,
                  styles.italicText
                )}
              >
                Edited
              </p>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NotesCard;
