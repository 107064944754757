import React from "react";
import styles from "./EmptyNotesList.module.css";
import { getCrmAsset } from "features/Crm/utils/Crm.utils";

const EmptyNotesList = () => {
  return (
    <div className={styles.emptyListWrapper}>
      <img src={getCrmAsset("empty_notes_list.svg")} />
      <span className={styles.emptyListTitle}>It’s empty in here!</span>
      <span className={styles.emptyListSubtitle}>
        You can see the notes you or your organisation members add here
      </span>
    </div>
  );
};

export default EmptyNotesList;
