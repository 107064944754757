import React from "react";
import styles from "./Media.module.css";
import ExlyImage from "common/Components/ExlyImage";
import { getCrmAsset } from "features/Crm/utils/Crm.utils";

export const EmptyMediaResponse = () => {
  return (
    <div className={styles.emptyMediaContainer}>
      <ExlyImage
        src={getCrmAsset("attach_file_off.svg")}
        alt="No file"
        height={16}
        width={16}
      />
      <p className={styles.emptyMediaText}>No file uploaded</p>
    </div>
  );
};
