import { useState } from "react";
import { format } from "date-fns";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { addNotes, getNotes } from "./CrmNotes.utils";
import { isRequestSuccessful } from "utils/Utils";
import { groupDataByDate } from "features/Crm/utils/Crm.utils";
import { orgPermissions } from "utils/OrgPermissions";
import { last_div_id } from "features/Crm/Crm.constants";
import { onScrollToDiv } from "features/Common/utils/common.utils";
import { YEAR_MONTH_DAY_FORMAT } from "features/Common/modules/DateTime/DateTime.constants";

const useCrmNotes = ({ emailId }) => {
  const { notify } = useNotifications();
  const [loading, setLoading] = useState(false);
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [notesList, setNotesList] = useState({});
  const [hasMore, setHasMore] = useState(true);

  const onAddNotes = async ({ message, emailId, setNoteValues }) => {
    try {
      setAddNoteLoading(true);
      await addNotes({ message, emailId });

      const newMessage = {
        created_at: new Date(),
        message,
        uuid: new Date().toISOString(),
        role_type: orgPermissions.getRoleType(),
        action_by_login_user: true,
      };

      // Format the date to the same format used in the notesList
      const date = format(
        new Date(newMessage.created_at),
        YEAR_MONTH_DAY_FORMAT
      ); // Extract the date part

      // Update the notes list state
      setNotesList((prev) => {
        const previousNotesList = { ...prev };
        const newData = {};

        // If the date exists, prepend the new message
        if (previousNotesList[date]) {
          newData[date] = [newMessage, ...previousNotesList[date]];
          delete previousNotesList[date];
        } else {
          // If the date does not exist, create a new array with the new message
          newData[date] = [newMessage];
        }

        return { ...newData, ...previousNotesList };
      });

      onScrollToDiv({ elementId: last_div_id });
      notify("Note added successfully!", notification_color_keys.success);
      setNoteValues("");
    } catch (err) {
      notify(
        err.message || "Error while adding notes",
        notification_color_keys.error
      );
    } finally {
      setAddNoteLoading(false);
    }
  };

  const getNotesList = async ({ page }) => {
    if (!hasMore || loading) return;
    try {
      setLoading(true);

      const { data, status } = await getNotes({ email: emailId, page });

      if (isRequestSuccessful(status)) {
        const parsedNotesList = groupDataByDate({
          data: data?.notes,
        });

        setNotesList((prev) => {
          const previousNotesList = { ...prev };
          const newData = {};
          Object.entries(parsedNotesList).forEach(([date, notes]) => {
            // If not, create a new array for this date
            if (previousNotesList[date]) {
              // If the date exists, concatenate the arrays with new notes first
              newData[date] = [...previousNotesList[date], ...notes];
              delete previousNotesList[date];
            } else {
              // If the date does not exist, add the new array
              newData[date] = notes;
            }
          });
          return { ...previousNotesList, ...newData };
        });

        setHasMore(data?.total_pages > page);
      }
    } catch (err) {
      notify(
        err.message || "Error while getting notes",
        notification_color_keys.error
      );
    } finally {
      setLoading(false);
    }
  };

  return { notesList, onAddNotes, addNoteLoading, loading, getNotesList };
};

export default useCrmNotes;
