export const TELEGRAM_SUBSCRIPTION_STATUSES = {
  ACTIVE: 1,
  DUE: 2,
  OVERDUE: 3,
  INACTIVE: 4,
};

export const TELEGRAM_SUBSCRIPTION_STATUS_MAP = {
  [TELEGRAM_SUBSCRIPTION_STATUSES.ACTIVE]: {
    title: "Active",
    className: "active_status",
  },
  [TELEGRAM_SUBSCRIPTION_STATUSES.DUE]: {
    title: "Due",
    className: "due_status",
  },
  [TELEGRAM_SUBSCRIPTION_STATUSES.OVERDUE]: {
    title: "Overdue",
    className: "over_due_status",
  },
  [TELEGRAM_SUBSCRIPTION_STATUSES.INACTIVE]: {
    title: "Expired",
    className: "inactive_status",
  },
};
