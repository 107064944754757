/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Avatar } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import React, { useEffect } from "react";
import { parsePhoneNumber } from "../../../../../../utils/Utils";
import CustomerTransactionsDetails from "../../../../../../ui/pages/customers/CustomerTransactionsV2/CustomerTransactionsDetails";
import useStyles from "../../../../../../ui/pages/customers/CustomerTransactionsV2/CustomerTransactionsV2.styles";
import styles from "./CustomerTransactionsModal.module.css";

const CustomerTransactionsModal = ({
  open,
  onClose,
  countryCodeKey = "country_code",
}) => {
  let customerData =
    JSON.parse(window.sessionStorage.getItem("customer")) || {};
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });

  useEffect(() => {
    if (document.querySelector("#CustomGrid")) {
      document
        .querySelectorAll("#CustomGrid")
        .forEach((item) => (item.firstChild.firstChild.style.display = "none"));
    }
  }, []);

  const handleWhatsappConnect = ({
    record = {},
    source,
    countryCodeSource,
  }) => {
    const phone_number = parsePhoneNumber(record, {
      countryCodeKey: countryCodeSource,
      phoneNumberKey: source,
    });
    if (record[source])
      window.open(
        `https://api.whatsapp.com/send?phone=${phone_number.substring(1)}`,
        "_blank"
      );
  };

  const desktopTitle = (
    <div className={classes.desktop_header}>
      <Avatar
        label={customerData.customer_name}
        colorIndex="10"
        className={classes.desktop_header_avatar}
      />
      <div className={classes.flexGrow}>
        <div className={classes.customer_name}>
          {customerData.customer_name}
        </div>
        <div className={classes.desktop_contact_section}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${customerData.customer_email}`}
            className={classes.desktop_customer_email}
          >
            {customerData.customer_email}
          </a>
          <span className={classes.divider} />
          <span
            className={classnames(
              classes.phone_number,
              classes.desktop_phone_number
            )}
          >
            {customerData.phone_number
              ? parsePhoneNumber(customerData, {
                  countryCodeKey,
                  phoneNumberKey: "phone_number",
                })
              : "N/A"}
          </span>
          {customerData.phone_number ? (
            <IconButton
              className={classes.mobile_whatsapp_icon_wrapper}
              onClick={() =>
                handleWhatsappConnect({
                  record: customerData,
                  source: "phone_number",
                  countryCodeSource: countryCodeKey,
                })
              }
            >
              <img
                src={require("../../../../../../assets/images/whatsapp.png")}
                className={classes.mobile_whatsapp_icon}
                alt="whatsapp"
              />
            </IconButton>
          ) : null}
        </div>
      </div>
      <IconButton
        disableRipple
        disableFocusRipples
        component="span"
        className={classes.mobile_close_icon_wrapper}
        onClick={onClose}
      >
        <CloseRoundedIcon
          viewBox="3 4 19.06 17.01"
          className={classes.mobile_close_icon}
        />
      </IconButton>
    </div>
  );

  const mobileTitle = (
    <div className={classes.mobile_header}>
      <Avatar label={customerData.customer_name} colorIndex="10" />

      <div className={classes.mobile_header_details}>
        <div className={classnames(classes.customer_name, classes.ellipsis)}>
          {customerData.customer_name}
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:${customerData.customer_email}`}
          className={classnames(
            classes.mobile_customer_email,
            classes.ellipsis
          )}
        >
          {customerData.customer_email}
        </a>
        <div className={classnames(classes.phone_number, classes.ellipsis)}>
          {customerData.phone_number
            ? parsePhoneNumber(customerData, {
                countryCodeKey: "country_code",
                phoneNumberKey: "phone_number",
              })
            : "N/A"}
        </div>
      </div>

      <div className={classes.mobile_header_actions}>
        {customerData.phone_number ? (
          <IconButton
            className={classes.mobile_whatsapp_icon_wrapper}
            onClick={() =>
              handleWhatsappConnect({
                record: customerData,
                source: "phone_number",
                countryCodeSource: countryCodeKey,
              })
            }
          >
            <img
              src={require("../../../../../../assets/images/whatsapp.png")}
              className={classes.mobile_whatsapp_icon}
              alt="whatsapp icon"
            />
          </IconButton>
        ) : null}

        <IconButton
          disableRipple
          disableFocusRipples
          component="span"
          className={classes.mobile_close_icon_wrapper}
          onClick={onClose}
        >
          <CloseRoundedIcon
            viewBox="3 4 19.06 17.01"
            className={classes.mobile_close_icon}
          />
        </IconButton>
      </div>
    </div>
  );

  return (
    <ExlyModal
      open={open}
      onClose={onClose}
      modal_props={{
        modalPaperClassName: classes.desktop_modal_paper,
        customFooter: <></>,
        customHeader: desktopTitle,
      }}
      mobile_modal_props={{
        customFooter: <></>,
        customHeader: mobileTitle,
      }}
      padded={false}
    >
      <CustomerTransactionsDetails
        record={customerData}
        className={styles.root}
      />
    </ExlyModal>
  );
};

export default withComponentLibraryTheme(CustomerTransactionsModal);
