import {
  DateTimeField,
  WhatsAppPhoneField,
} from "common/Components/TableFields/TableFields";
import { getTransactionTypeConfig } from "features/Crm/modules/Transactions/utils/Transactions.tableConfigs";
import React from "react";
import MoneyField from "ui/modules/MoneyField";
import { FORM_KEYS } from "../ReviewSelection.constants";

export const getColumnConfig = () => [
  {
    field: FORM_KEYS.customerName.key,
    headerName: FORM_KEYS.customerName.label,
    avatar: true,
    isPrimary: true,
    isDrawerTitle: true,
    emptyField: "N/A",
    sortable: false,
    fixed: "left",
    width: "200px",
  },
  {
    field: FORM_KEYS.customerEmail.key,
    headerName: FORM_KEYS.customerEmail.label,
    emptyField: "N/A",
    sortable: false,
    width: "120px",
    columnClassName: "word_break",
  },
  {
    field: FORM_KEYS.customerPhone.key,
    headerName: FORM_KEYS.customerPhone.label,
    valueFormatter: (record) => (
      <WhatsAppPhoneField
        record={record}
        source={FORM_KEYS.customerPhone.key}
        countryCodeKey={FORM_KEYS.customerPhone.countryCodeKey}
      />
    ),
    sortable: false,
  },
  {
    field: FORM_KEYS.listingName.key,
    headerName: FORM_KEYS.listingName.label,
    sortable: false,
    emptyField: "N/A",
    noWrap: true,
    ellipsis: true,
    maxCharCount: 30,
  },
  {
    field: FORM_KEYS.transactionAmount.key,
    headerName: FORM_KEYS.transactionAmount.label,
    valueFormatter: (record) => (
      <MoneyField
        record={record}
        source={FORM_KEYS.transactionAmount.key}
        showGstHelper={true}
        creator_sales={false}
        noRightMargin={true}
      />
    ),
    sortable: false,
  },
  {
    field: FORM_KEYS.transactionDate.key,
    headerName: FORM_KEYS.transactionDate.label,
    valueFormatter: (record) => (
      <DateTimeField record={record} source={FORM_KEYS.transactionDate.key} />
    ),
    sortable: false,
    noWrap: true,
  },
  getTransactionTypeConfig(),
];
