import { app_route_ids, app_route_keys } from "constants/urlPaths";

const pathName = app_route_ids[app_route_keys.review_selection_modal];

export const REVIEW_SELECTION_RA_PROPS = {
  basePath: pathName,
  history: {
    location: {
      pathname: pathName,
      search: "",
      hash: "",
    },
  },
  location: {
    pathname: pathName,
    search: "",
    hash: "",
  },
  match: {
    path: pathName,
    url: pathName,
    isExact: true,
    params: {},
  },

  resource: pathName,
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
};

export const FORM_KEYS = {
  customerEmail: {
    key: "customer_email",
    label: "Email",
  },
  customerName: {
    key: "customer_name",
    label: "Name",
  },
  customerPhone: {
    key: "customer_phone",
    label: "Phone number",
    countryCodeKey: "customer_country_code",
  },
  listingName: {
    key: "listing_name",
    label: "Offering Name",
  },
  transactionAmount: {
    key: "transaction_amount",
    label: "Transaction amount",
  },
  transactionDate: {
    key: "created_at",
    label: "Transaction date",
  },
};
