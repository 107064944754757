import {
  DEFAULT_SCROLL_HEIGHT,
  INITIAL_PAGE,
  SCROLL_HEIGHT_OFFSET,
  SCROLL_TOLERANCE,
  SCROLL_TOP_OFFSET,
} from "features/Crm/Crm.constants";
import { useEffect, useRef, useState } from "react";

const useInfiniteSearchToTop = (dateKeys) => {
  const [page, setPage] = useState(INITIAL_PAGE);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const containerRef = useRef(null);
  const dateHeadersRef = useRef({});
  const [lastScrollHeight, setLastScrollHeight] = useState(
    DEFAULT_SCROLL_HEIGHT
  );
  const [floatingDate, setFloatingDate] = useState(null);
  const prevScrollTop = useRef(0); // Store the previous scroll position using useRef
  const [maxPage, setMaxPage] = useState();
  const [isScrollingDown, setIsScrollingDown] = useState(false);

  useEffect(() => {
    let observer;
    if (dateKeys) {
      // @dev - This intersection observer is used to set floating header date which is shown in timeline logs view
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const date = entry.target.getAttribute("data-date");
            const dateIndex = dateKeys?.indexOf(date);
            const { scrollTop, scrollHeight, clientHeight } =
              containerRef.current;

            if (
              scrollTop >= SCROLL_TOLERANCE ||
              scrollHeight + scrollTop === clientHeight
            ) {
              setFloatingDate(null);
              return;
            }

            if (entry.isIntersecting) {
              if (isScrollingDown) {
                // For downward scroll, set the next date as floating
                setFloatingDate(date);
              } else {
                // For upward scroll, set the previous date as floating
                const previousDate =
                  dateIndex < dateKeys.length - 1
                    ? dateKeys[dateIndex + 1]
                    : null;
                setFloatingDate(previousDate || date);
              }
            }
          });
        },
        {
          root: containerRef.current,
          threshold: [0.25, 0.5, 0.75], // Better control for partial visibility
          rootMargin: "-10px 0px -10px 0px", // Smooth triggering at edges
        }
      );

      // Observe each date header
      dateKeys.forEach((dateKey) => {
        if (dateHeadersRef.current[dateKey]) {
          observer.observe(dateHeadersRef.current[dateKey]);
        }
      });
    }

    return () => {
      // Cleanup observer on unmount
      if (observer) observer.disconnect();
    };
  });

  /**
   * Handles scroll events on the container element.
   *
   * This function triggers pagination when scrolled to the top and toggles
   * the visibility of a "scroll to bottom" button based on the scroll position.
   */
  const onScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      // Check if the user is scrolling down or up
      const isDown = scrollTop > prevScrollTop.current + 10;

      // Update the state and previous scroll position
      setIsScrollingDown(isDown);
      prevScrollTop.current = scrollTop; // Update ref value for next scroll

      if (scrollTop === 0) {
        setPage(INITIAL_PAGE);
        setLastScrollHeight(DEFAULT_SCROLL_HEIGHT);
      }

      if (!(maxPage && maxPage <= page)) {
        // Trigger pagination if scrolled to the very top
        const top =
          scrollHeight + scrollTop <= clientHeight + SCROLL_TOP_OFFSET;
        if (top && lastScrollHeight + SCROLL_HEIGHT_OFFSET <= scrollHeight) {
          setPage((prev) => prev + 1);
          setLastScrollHeight(scrollHeight);
        }
      }

      // Show the "scroll to bottom" button if not near the bottom
      const scrollToBottom = Math.abs(scrollTop) > clientHeight ? true : false;
      setShowScrollToBottom(scrollToBottom);
    }
  };

  return {
    onScroll,
    containerRef,
    page,
    showScrollToBottom,
    setMaxPage,
    setPage,
    dateHeadersRef,
    floatingDate,
  };
};

export default useInfiniteSearchToTop;
