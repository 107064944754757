import { dataProvider } from "data";
import { APIS } from "../Refund.constants";
import { apiMethods } from "data/api.constants";

/**
 * in order to show paginated refund review selection data we need request id of the selected transactions
 * @param {*} param0 transactionUuids: selected transaction uuid to fetch the refund details for
 * @returns api response containing request id
 */
export const initiateRefundRequest = async ({ transactionUuids }) => {
  const response = await dataProvider.custom_request(
    APIS.post_initiate_refund,
    apiMethods.POST,
    { transaction_uuids: transactionUuids }
  );
  return response;
};
