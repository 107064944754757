import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";
import DateRangeSharpIcon from "@material-ui/icons/DateRangeSharp";
import useInfiniteSearch from "hooks/useInfiniteSearch";
import ExlyLoader from "ui/modules/ExlyLoader";
import EmptyResponseScreen from "ui/pages/customers/CrmDetails/EmptyResponseScreen";
import { is_empty } from "utils/validations";
import { DATE_MONTH_TIME_FORMAT } from "constants/date-fns/dateTime.constant";
import { action_type_mapping } from "features/Crm/modules/CrmResponses/constants/CrmResponses.constants";
import { questions_type_mapping } from "constants/customerQuestions.constants";
import cssStyles from "./responsesStyle.module.css";
import classnames from "classnames";
import { useCrmResponses } from "features/Crm/modules/CrmResponses/utils/hooks/useCrmResponses";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const Responses = () => {
  const [responsesList, setResponsesList] = useState([]);
  const { loading, setHasMore, lastElementRef, pageNumber, setLoading } =
    useInfiniteSearch();
  const { responsesPayload } = useSelector((state) => state.crm);
  const isDesktop = useDesktopMediaQuery();
  const { getAllResponsesPaginated } = useCrmResponses();

  useEffect(() => {
    getAllResponsesPaginated({
      setLoading,
      responsesPayload,
      pageNumber,
      setHasMore,
      setResponsesList,
    });
  }, [pageNumber]);

  return (
    <>
      {is_empty(responsesList) && !loading ? (
        <EmptyResponseScreen wrapperClassname={cssStyles.wrapperClassname} />
      ) : (
        <div className={cssStyles.container}>
          {responsesList?.map((response) => (
            <div key={response} className={cssStyles.card}>
              <div className={cssStyles.header}>
                <div className={cssStyles.headerText}>
                  {
                    Object.values(action_type_mapping)?.find(
                      (item) => item.value === response.action_type
                    )?.label
                  }
                </div>
                <div
                  className={classnames(
                    cssStyles.iconWrapperBox,
                    !isDesktop && cssStyles.mobileIconWrapperBox
                  )}
                >
                  <div className={cssStyles.iconWrapper}>
                    <ExitToAppSharpIcon className={cssStyles.titleIcon} />
                    <div className={cssStyles.iconText}>
                      {response.source_title}
                    </div>
                  </div>
                  <div className={cssStyles.iconWrapper}>
                    <DateRangeSharpIcon className={cssStyles.titleIcon} />
                    <div className={cssStyles.iconText}>
                      {format(
                        new Date(response.action_timestamp),
                        DATE_MONTH_TIME_FORMAT
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={cssStyles.content}>
                {response.action_type ===
                  action_type_mapping.GET_IN_TOUCH.value &&
                  response.message && (
                    <div>
                      <div className={cssStyles.message}>Message</div>
                      <p className={cssStyles.responseText}>
                        {response.message}
                      </p>
                    </div>
                  )}
                {response?.responses?.map((responseItem) => (
                  <div key={responseItem} className={cssStyles.questionWrapper}>
                    <div className={cssStyles.questionText}>
                      {responseItem.question_text}
                    </div>
                    {questions_type_mapping[
                      responseItem.question_type
                    ]?.showAnswer(responseItem)}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div
            className={cssStyles.lastElementStyle}
            ref={lastElementRef}
          ></div>
          {loading && <ExlyLoader />}
        </div>
      )}
    </>
  );
};

export default Responses;
