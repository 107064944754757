import { useDispatch } from "react-redux";
import {
  getResponses,
  parsedResponseRow,
} from "features/Crm/modules/CrmResponses/CrmResponses.utils";
import { is_empty } from "utils/validations";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import constants from "constants/constants";
import { action_type_mapping } from "features/Crm/modules/CrmResponses/constants/CrmResponses.constants";
import { SAVE_RESPONSES_PAYLOAD } from "features/Crm/modules/CrmResponses/utils/redux/Crm.action";

export const useCrmResponses = () => {
  const dispatch = useDispatch();
  const { notify } = useNotifications();

  const setResponsesPayloadToRedux = ({ entityType, uniqueId }) => {
    dispatch({
      type: SAVE_RESPONSES_PAYLOAD,
      payload: {
        entityType,
        uniqueId,
      },
    });
  };

  /**
   * Fetches and filters response data based on provided parameters and updates state accordingly.
   * @param {function} setLoading - Function to set loading state.
   * @param {object} responsesPayload - Current CRM state object containing entityType, uniqueId, and actionType.
   * @param {number} pageNumber - The page number to fetch responses from.
   * @param {function} setHasMore - Function to set whether more data is available.
   * @param {function} setResponsesList - Function to update the responses list state.
   * @returns {void}
   */
  const getAllResponsesPaginated = async ({
    setLoading,
    responsesPayload,
    pageNumber,
    setHasMore,
    setResponsesList,
  }) => {
    setLoading(true);
    try {
      const { data } = await getResponses({
        pageNumber,
        entityType: responsesPayload.entityType,
        uniqueId: responsesPayload.uniqueId,
        actionType: responsesPayload.actionType,
      });
      setHasMore(data?.total_pages > pageNumber);
      setResponsesList((prevResponsesList) => [
        ...prevResponsesList,
        ...data?.crm_responses?.filter(
          (responses) => responses.message || !is_empty(responses?.responses)
        ), // we only need to map the responses on ui which either have message or responses array.
      ]);
    } catch (error) {
      logError({
        error,
        occuredAt: "src/features/CrmResponses/utils/hooks/useCrmResponses.js",
        when: "on getAllResponsesPaginated",
      });
      notify(
        error.message || SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    }
    setLoading(false);
  };

  /**
   * Fetches filtered responses based on tab value, lead status, and record details, and updates state accordingly.
   * @param {string} tabValue - The value representing the current tab.
   * @param {boolean} isLead - Indicates whether the record is a lead or not.
   * @param {object} record - The record object containing id or username based on lead status.
   * @param {function} setLoading - Function to set loading state.
   * @param {function} setRows - Function to update the rows state with filtered responses.
   * @returns {void}
   */
  const getFilteredResponses = async ({
    tabValue,
    isLead,
    record,
    setLoading,
    setRows,
  }) => {
    const uniqueId = isLead ? record.id : record.username;
    const entityType = isLead
      ? constants.entity_type.leads.value
      : constants.entity_type.customers.value;
    const actionType = Object.values(action_type_mapping)?.find(
      (item) => item.slug === tabValue
    )?.value;

    try {
      setLoading(true);
      let queryParams = { uniqueId, entityType, actionType };
      const { data } = await getResponses(queryParams);
      const localRows = parsedResponseRow(tabValue, data?.crm_responses) || [];
      setRows(localRows);
      setLoading(false);
    } catch (error) {
      console.log("error fetching get in touch responses");
      logError({
        error,
        occuredAt: "src/features/CrmResponses/utils/hooks/useCrmResponses.js",
        when: "getFilteredResponses",
      });
      setLoading(false);
    }
  };

  return {
    setResponsesPayloadToRedux,
    getAllResponsesPaginated,
    getFilteredResponses,
  };
};
