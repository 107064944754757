import constants from "constants/constants";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";
import {
  removeSessionStorageItem,
  setSessionStorageItem,
  getSessionStorageItem,
} from "features/Common/modules/Storage/modules/SessionStorage/utils/SessionStorage.utils";
import { SCROLL_TO_ROW_DELAY } from "features/Crm/Crm.constants";
import { useHistory } from "react-router";

export const useCustomerDetailsNavigations = () => {
  const history = useHistory();

  const handleCustomerDetailNavigation = ({ entityType, customerId }) => {
    // @dev - setting id of the table row to be used to scroll to that row when coming back from details view
    setSessionStorageItem(SESSION_STORAGE_KEYS.TABLE_ROW_ID, customerId);

    // navigating to customer details view
    history.push(
      `${
        app_route_ids[app_route_keys.customer_details]
      }?id=${customerId}&entity_type=${entityType}`
    );
  };

  const handleBookingNavigationToCustomer = (record) => {
    handleCustomerDetailNavigation({
      entityType: constants.entity_type.customers.value,
      customerId: record.username,
    });
  };

  const scrollToLastViewedRow = () => {
    const tableRowId = getSessionStorageItem(SESSION_STORAGE_KEYS.TABLE_ROW_ID);
    if (tableRowId) {
      setTimeout(() => {
        const divElement = document.getElementById(tableRowId);
        divElement &&
          divElement.scrollIntoView({ behavior: "smooth", block: "center" });
        removeSessionStorageItem(SESSION_STORAGE_KEYS.TABLE_ROW_ID);
      }, SCROLL_TO_ROW_DELAY);
    }
  };

  return {
    handleCustomerDetailNavigation,
    handleBookingNavigationToCustomer,
    scrollToLastViewedRow,
  };
};
