import { InputAdornment } from "@material-ui/core";
import classnames from "classnames";
import { ExlyInput } from "common/form";
import { finalFormParsers } from "common/validate";
import constants from "constants/constants";
import VisibleTickCheckbox from "features/VisibleTickCheckbox/VisibleTickCheckbox";
import React from "react";
import { Field } from "react-final-form";
import styles from "./InstalmentFieldSkeleton.module.css";
import { formValidators } from "./InstalmentFieldSkeleton.utils";

const InstalmentFieldSkeleton = ({
  priceFieldName,
  currency,
  instalmentNumber,
  disabled,
  isMarkedPaid,
  onClickMark,
  className,
  actionsClassName,
}) => {
  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.label}>Instalment {instalmentNumber}</div>

      <div className={classnames(styles.actions, actionsClassName)}>
        <Field
          name={priceFieldName}
          component={ExlyInput}
          startAdornment={
            <InputAdornment
              className={classnames(
                styles.currencySymbol,
                disabled && styles.disabled
              )}
              position="start"
            >
              {constants.CURRENCY_SYMBOL_MAP[currency]}
            </InputAdornment>
          }
          size="thin"
          type="number"
          parse={finalFormParsers.number}
          inputClassName={styles.inputParentWrapper}
          validate={formValidators.price}
          disabled={disabled}
        />

        <VisibleTickCheckbox
          active={isMarkedPaid}
          onClick={onClickMark}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default InstalmentFieldSkeleton;
