import { dataProvider } from "data";
import { CUSTOMER_TRANSACTIONS_APIS } from "../constants/CustomerTransactions.api";
import { isRequestSuccessful } from "utils/Utils";
import { apiMethods } from "data/api.constants";

export const fetchBookedListingTypes = async (username) => {
  const { data, status } = await dataProvider.custom_request(
    CUSTOMER_TRANSACTIONS_APIS.get_booked_listing_types,
    apiMethods.GET,
    { guest_username: username }
  );

  if (!isRequestSuccessful(status))
    throw new Error(
      `Error fetching purchased listing types: ${data} ${status}`
    );

  return data;
};
