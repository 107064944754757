import React, { useState } from "react";
import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import styles from "./CrmNotes.module.css";
import { ExlyInput } from "common/form";
import ExlyImage from "common/Components/ExlyImage";
import { hasPermissionToEditLogs } from "webpage-leads/webpageHelpers";
import { InputAdornment } from "@material-ui/core";
import useCrmNotes from "./utils/useCrmNotes";
import CrmNotesList from "./modules/CrmNotesList/CrmNotesList";
import { getCrmAsset } from "features/Crm/utils/Crm.utils";
import { is_empty } from "features/Common/utils/common.utils";

function CrmNotes({ open, onClose, emailId, feature }) {
  const [noteValues, setNoteValues] = useState("");
  const { onAddNotes, getNotesList, addNoteLoading, loading, notesList } =
    useCrmNotes({
      emailId,
    });

  const onAddNotesBtnClick = () => {
    onAddNotes({ message: noteValues, emailId, setNoteValues });
  };

  return (
    <ExlyModal
      title={
        <div className={styles.titleWrapper}>
          <span className={styles.titleText}>Notes</span>
          <ExlyImage
            src={getCrmAsset("add_note_icon.svg")}
            alt="Add Note"
            height={20}
            width={20}
          />
        </div>
      }
      open={open}
      onClose={onClose}
      showSecondaryBtn={false}
      showpPrimaryBtn={false}
      modal_props={{
        modalPaperClassName: styles.modalPaperClass,
        backDropClickClose: true,
        primaryBtnFullWidth: true,
      }}
      mobile_modal_props={{
        keepMounted: true,
      }}
      customFooter={
        <div className={styles.footerWrapper}>
          <ExlyInput
            value={noteValues}
            placeholder="Write a note"
            inputClassName={styles.inputStyle}
            onChange={(value) => setNoteValues(value)}
            disabled={!hasPermissionToEditLogs(feature)}
            wrapperClassName={styles.inputWrapper}
            inputElementClassName={styles.inputElementClassName}
            startAdornment={
              <InputAdornment
                position="start"
                className={styles.adornmentIconStyle}
              >
                <ExlyImage
                  src={getCrmAsset("add_note.svg")}
                  alt="Add Note"
                  className="mt-1"
                />
              </InputAdornment>
            }
            multiline
          />
          <button
            className={styles.addNoteBtn}
            onClick={onAddNotesBtnClick}
            disabled={is_empty(noteValues?.trim()) || addNoteLoading}
          >
            <ExlyImage
              src={getCrmAsset("download_done.svg")}
              alt="Add Note"
              height={24}
              width={24}
            />
          </button>
        </div>
      }
      padded={false}
    >
      <CrmNotesList
        loading={loading}
        notesList={notesList}
        getNotesList={getNotesList}
      />
    </ExlyModal>
  );
}

export default withComponentLibraryTheme(CrmNotes);
