import React from "react";
import styles from "./DateHeader.module.css";
import { getDisplayDate } from "features/Crm/utils/Crm.utils";

const DateHeader = ({ date }) => {
  return (
    <div className={styles.dateDivWrapper}>
      <div className={styles.horizontalDiv}></div>
      <div className={styles.dateDiv}>{getDisplayDate({ date })}</div>
      <div className={styles.horizontalDiv}></div>
    </div>
  );
};

export default DateHeader;
