import { checkIsArrayEmpty } from "@my-scoot/exly-react-component-lib";
import {
  CUSTOMER_ACTIVITY_TABS,
  LISTING_TABS_MAP,
} from "../constants/CustomerTransactions.constants";

export const getActivityTabs = (bookedListingTypes) => {
  if (checkIsArrayEmpty(bookedListingTypes)) return CUSTOMER_ACTIVITY_TABS;

  const tabs = [...CUSTOMER_ACTIVITY_TABS];

  bookedListingTypes.forEach((listingType) => {
    const tab = LISTING_TABS_MAP[listingType];
    if (tab) tabs.push(tab);
  });

  return tabs;
};
