import { SET_CHANGE_REQUEST_TYPE } from "./customerDetails.actions";

const customerDetailsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_CHANGE_REQUEST_TYPE: {
      return {
        ...state,
        changeRequestType: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default customerDetailsReducer;
